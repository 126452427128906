import {Component, OnInit} from '@angular/core';
import {TokenUtilsService} from '../modules/shared/services/utils/token-utils/token-utils.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-public-dashboard',
  templateUrl: './public-dashboard.component.html',
  styleUrls: ['./public-dashboard.component.scss'],
})
export class PublicDashboardComponent implements OnInit {
  constructor(
    private tokenUtils: TokenUtilsService,
    private router: Router
  ) {
  }

  async ngOnInit() {
    if (this.tokenUtils.isTokenValid()) {
      await this.router.navigate(['user/home']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async logIn() {
    await this.router.navigateByUrl('/login');
  }

  async navigate() {
    await this.router.navigate(['/login']);
  }
}

export const environment = {
  production: true,
  baseUrl: 'https://ca11zxltxf.execute-api.eu-central-1.amazonaws.com/pfs/api/v1',
  rootUrl: 'https://ca11zxltxf.execute-api.eu-central-1.amazonaws.com/pfs',
  messagingUrl: 'https://pfs-matching-app.com/user/messaging/candidate/',
  homeUrl: 'https://pfs-matching-app.com/login',
  deleteCode: 'u7Ws%',
  zippedDocuments: 'https://drive.google.com/drive/folders/1MnlRPPGo9xUz5K4GUHZ08immUa9Q76Fx',
  baseHref: '/',
  confirmAppointment: 'https://ca11zxltxf.execute-api.eu-central-1.amazonaws.com/pfs/api/v1/appointment/confirm/'
};
